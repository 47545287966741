























































































































































































































































import Vue from 'vue'
import Component from 'vue-class-component'
import { vxm } from '@/store'
import { Watch } from 'vue-property-decorator'
import { appendSitePrefix } from "@/utils/routeUtils";

@Component({})
export default class InvoiceDeliveryList extends Vue {
  private dialogDetailsVisible = false
  private dialogDetailsData = null
  private dialogCheckVisible = false
  private dialogCheckData = null
  private dialogDetailsHistory = []
  private dialogDetailsHistoryLoading = false

  private data = []
  private isLoading = false
  private isLoaded = false

  private status = ''
  private invoiceNumber = ''
  private siteId = ''
  private siteMode = 'this'
  private siteModes = []
  private statuses = []

  private get isSuperUser() {
    return vxm.user.isSuperUser
  }

  private mounted() {
    this.siteModes = [
      { id: 'this', name: this.$t('This site') },
      { id: 'all', name: this.$t('All sites') },
      { id: 'other', name: this.$t('Another site') },
    ]
    this.statuses = [
      { id: '', name: 'All' },
      { id: 'SendError', name: 'SendError' },
      { id: 'SendException', name: 'SendException' },
      { id: 'ExchangeError', name: 'ExchangeError' },
      { id: 'RecipientNotFound', name: 'RecipientNotFound' },
    ]
    this.onRouteChange()
  }

  private load() {
    this.isLoading = true
    let params = 'siteMode=' + this.siteMode
    if (this.siteMode === 'other') {
      params += '&siteId=' + this.siteId
    }
    if (this.invoiceNumber && this.siteMode !== 'all') {
      params += '&invoiceNumber=' + this.invoiceNumber
    }
    if (this.status && !this.invoiceNumber) {
      params += '&status=' + this.status
    }
    this.$axios
      .get('/v4/site/economy/invoice-delivery/log?' + params)
      .then((response) => {
        this.data = response.data.data
        this.isLoading = false
        this.isLoaded = true
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to load delivery-log')
        this.isLoading = false
      })
  }

  private showDetails(item) {
    this.dialogDetailsData = {}
    this.dialogDetailsData = Object.assign({}, item)
    this.dialogDetailsVisible = true
    this.dialogDetailsHistory = []
    this.dialogDetailsHistoryLoading = true
    this.$axios
      .get('/v4/site/economy/invoice-delivery/log/history?invoiceId=' + item.invoiceId)
      .then((response) => {
        this.dialogDetailsHistory = response.data.data
        this.dialogDetailsHistoryLoading = false
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to fetch log history')
        this.dialogDetailsHistoryLoading = false
      })
  }

  private getInvoiceLink(item) {
    return '/' + item.siteId + '/search/a:' + item.invoiceNumber
  }

  private check(item) {
    this.dialogCheckVisible = true
    this.dialogCheckData = null
    this.$axios
      .get('/v4/site/economy/invoice-delivery/check?invoiceId=' + item.invoiceId)
      .then((response) => {
        this.dialogCheckData = response.data.data
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to run check')
      })
  }

  private retry(item) {
    if (item.mode !== 'Peppol') {
      alert(this.$t('Cannot retry when delivery mode is not Peppol'))
      return
    }
    if (
      !confirm(
        this.$t('If invoice has already been successfully sent, this may create a duplicate.') +
          '\n\n' +
          this.$t('Are you sure?'),
      )
    ) {
      return
    }
    this.dialogCheckVisible = true
    this.dialogCheckData = null
    this.$axios
      .get('/v4/site/economy/invoice-delivery/retry?force=1&invoiceId=' + item.invoiceId)
      .then((response) => {
        this.dialogCheckData = response.data.data
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to run check')
      })
  }

  private skip(item) {
    if (item.mode !== 'Peppol') {
      alert(this.$t('Cannot skip when delivery mode is not Peppol'))
      return
    }
    const result = prompt(
      this.$t('Invoice will be marked as "should not be distributed".') + '\n\n' + this.$t('Enter a reason for why:'),
    )
    if (result === null) {
      return
    }
    if (result === '') {
      alert('Must enter a reason')
      return
    }
    this.$axios
      .get(
        '/v4/site/economy/invoice-delivery/skip?invoiceId=' +
          item.invoiceId +
          '&comment=' +
          encodeURIComponent(result),
      )
      .then((_response) => {
        this.load()
      })
      .catch((err) => {
        vxm.alert.onAxiosError(err, 'Failed to run skip')
      })
  }

  private clickUpdate() {
    this.navigate({})
  }

  private clickReset() {
    this.navigate({
      siteMode: 'this',
      siteId: '',
      invoiceNumber: '',
    })
  }

  private clickSite(siteId: number) {
    this.navigate({ siteMode: 'other', siteId })
  }

  @Watch('$route', { deep: true })
  private onRouteChange() {
    this.siteMode = (this.$route.query.siteMode as string) || 'this'
    this.siteId = (this.$route.query.siteId as string) || ''
    this.invoiceNumber = (this.$route.query.invoiceNumber as string) || ''
    this.load()
  }

  private navigate(params) {
    if (params.siteMode === undefined) {
      params.siteMode = this.siteMode
    }
    if (params.siteId === undefined) {
      params.siteId = this.siteId
    }
    if (params.invoiceNumber === undefined) {
      params.invoiceNumber = this.invoiceNumber
    }
    params.ts = new Date().getTime() + ''
    this.$router.push({
      name: 'Economy/InvoiceDeliveryLog',
      query: params,
    })
  }

  private formatDate(value): string {
    if (!value) {
      return ''
    }
    return value.split('T')[0]
  }
}
